import * as React from "react";

import { AccommodationType, Unit } from "../../../mxts";
import { Resource as MxtsResource, Unit as MxtsUnit } from "@maxxton/cms-mxts-api";
import { backgroundColorPicker, fontColorPicker } from "../../../../utils/colorpicker.util";

import { CMSProvidedProperties } from "../../../../containers/cmsProvider.types";
import { DynamicWidgetBaseProps } from "../../../dynamic/dynamicWidget.types";
import { Icon } from "../../../../components/Icon";
import { ReservationContainerCRPProps } from "../../../dynamic/reservation/container/reservationContainer.types";
import { State } from "../../../../redux";
import { WidgetOptions } from "./";
import { getAccoTypeReservedResources } from "../../../../redux/reducers/myEnv/myEnv.util";
import { getI18nLocaleString } from "../../../../i18n";
import { getUnitOrAccoTypeFromReservation as getUnitOrAccoTypeFromEsReservation } from "../bedroomBathroom.util";
import namespaceList from "../../../../i18n/namespaceList";
import { setOpacityOnHide } from "../../../../components/utils";
import { useSelector } from "react-redux";

interface BathroomWidgetProps extends DynamicWidgetBaseProps<WidgetOptions>, ReservationContainerCRPProps {
    options: WidgetOptions;
    context: CMSProvidedProperties;
    accommodationType?: AccommodationType;
    unit?: Unit;
}

export const Bathroom = (props: BathroomWidgetProps) => {
    const { context, options, isMyEnvWidget, esReservationResult } = props;
    const { currentLocale, site } = context;
    const myEnvState = useSelector((state: State) => state.myEnvState);
    const [myEnvUnit, setMyEnvUnit] = React.useState<MxtsUnit>();
    const [myEnvAccoType, setMyEnvAccoType] = React.useState<MxtsResource>();
    const esReservation = isMyEnvWidget ? esReservationResult || myEnvState?.selectedReservation || undefined : undefined;

    const { bathroomFontColor, nameStyle, bathroomTextBackground, borderLineColor } = options;
    const hideWidget = setOpacityOnHide(options);

    React.useEffect(() => {
        getUnitOrAccoTypeFromEsReservation(context, esReservation).then(({ unit, accoType }) => {
            setMyEnvUnit(unit);
            setMyEnvAccoType(accoType);
        });
    }, [getAccoTypeReservedResources(esReservation)]);
    const accoTypeNrOfBathrooms = myEnvAccoType?.nrOfBathrooms || props.accommodationType?.nrOfBathrooms;
    const unitTypeNrOfBathrooms = myEnvUnit?.nrOfBathrooms || props.unit?.nrOfBathrooms;
    return accoTypeNrOfBathrooms || unitTypeNrOfBathrooms ? (
        <div className={`display-bathroom ${hideWidget} ${options.bathroomFontSize ? options.bathroomFontSize : ""}`}>
            <div
                // eslint-disable-next-line max-len
                className={`${options.nameStyle ? options.nameStyle : ""} border-bottom-${options.borderWidth ? options.borderWidth : "none"} 
                ${backgroundColorPicker(nameStyle, bathroomTextBackground, borderLineColor)}`}
                style={{
                    background:
                        options.nameStyle === "background-text" && options.bathroomTextBackground && options.bathroomTextBackground.indexOf("rgba") > -1 ? options.bathroomTextBackground : undefined,
                    borderColor: options.nameStyle === "underline-text" && options.borderLineColor && options.borderLineColor.indexOf("rgba") > -1 ? options.borderLineColor : undefined,
                }}
            >
                {!options.iconConfiguration?.iconRight && <Icon iconConfiguration={options.iconConfiguration} />}
                {
                    <span
                        // eslint-disable-next-line max-len
                        className={` ${fontColorPicker(bathroomFontColor)}`}
                        style={{ color: options.bathroomFontColor && options.bathroomFontColor.indexOf("rgba") > -1 ? options.bathroomFontColor : undefined }}
                    >
                        {`${accoTypeNrOfBathrooms || unitTypeNrOfBathrooms} ${
                            accoTypeNrOfBathrooms! > 1 || unitTypeNrOfBathrooms! > 1
                                ? getI18nLocaleString(namespaceList.pluginSearchResultsPanel, "bathrooms", currentLocale, site)
                                : getI18nLocaleString(namespaceList.dynamicBathroom, "bathroom", currentLocale, site)
                        }`}
                    </span>
                }
                {options.iconConfiguration?.iconRight && <Icon iconConfiguration={options.iconConfiguration} />}
            </div>
        </div>
    ) : null;
};
