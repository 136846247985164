import * as Icons from "../../../page/icons";
import * as React from "react";

import { FormSpec, multiSelectStylePicker } from "../../../../form-specs";
import { IconConfigurationOptions, IconConfigurationSpec, defaultIconConfigurationValues } from "../../../../utils/icon.util";

import { Bathroom } from "./displayBathroom";
import { TypesearchContainerWidgetSpec } from "../../../";
import { WidgetGroup } from "../../../widget.enum";
import { getI18nLocaleObject } from "../../../../i18n";
import namespaceList from "../../../../i18n/namespaceList";

export interface WidgetOptions {
    styleIds?: string[];
    bathroomFontSize: string;
    bathroomFontColor: string;
    nameStyle: string;
    bathroomTextBackground: string;
    borderLineColor: string;
    borderWidth: string;
    iconConfiguration: IconConfigurationOptions;
}

const TARGETS = ["bathroom"];
const iconList = Object.keys(Icons.default).map((key: any) => ({ label: (Icons.default as any)[key], value: key }));

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "results-panel-diaplay-bathroom-widget",
    name: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "bathroomWidgetOption"),
    pluralName: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "bathroomWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "style"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            IconConfigurationSpec<WidgetOptions, keyof WidgetOptions>({ variable: "iconConfiguration" }),

                            {
                                variable: "bathroomFontSize",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "fontSize"),
                                type: "select",
                                default: "default-font-size",
                                optionList: [
                                    {
                                        value: "default-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "defaultFontSize"),
                                    },
                                    {
                                        value: "micro-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "microFontSize"),
                                    },
                                    {
                                        value: "xx-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xxSmallFontSize"),
                                    },
                                    {
                                        value: "x-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xSmallFontSize"),
                                    },
                                    {
                                        value: "small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "smallFontSize"),
                                    },
                                    {
                                        value: "h6-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h6Fonts"),
                                    },
                                    {
                                        value: "h5-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h5Fonts"),
                                    },
                                    {
                                        value: "h4-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h4Fonts"),
                                    },
                                    {
                                        value: "h3-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h3Fonts"),
                                    },
                                    {
                                        value: "h2-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h2Fonts"),
                                    },
                                    {
                                        value: "h1-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h1Fonts"),
                                    },
                                ],
                            },
                            {
                                variable: "bathroomFontColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "fontColor"),
                                type: "dual-color",
                                default: "font-default",
                            },
                            {
                                variable: "nameStyle",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "nameStyle"),
                                type: "select",
                                default: "default",
                                optionList: [
                                    {
                                        value: "default",
                                        label: getI18nLocaleObject(namespaceList.admin, "default"),
                                    },
                                    {
                                        value: "background-text",
                                        label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "backgroundText"),
                                    },
                                    {
                                        value: "underline-text",
                                        label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "underlineText"),
                                    },
                                ],
                            },
                            {
                                variable: "bathroomTextBackground",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "textBackground"),
                                type: "dual-color",
                                default: "background-default",
                                visible: (options: WidgetOptions) => options.nameStyle === "background-text",
                            },
                            {
                                variable: "borderWidth",
                                label: getI18nLocaleObject(namespaceList.admin, "borderWidth"),
                                type: "number",
                                visible: (options: WidgetOptions) => options.nameStyle === "underline-text",
                            },
                            {
                                variable: "borderLineColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "borderLineColor"),
                                type: "dual-color",
                                default: "border-default",
                                visible: (options: WidgetOptions) => options.nameStyle === "underline-text",
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

export const resultsPanelDisplayBathroomWidget: TypesearchContainerWidgetSpec<WidgetOptions> = {
    id: "resultsPanelDisplayBathroom",
    type: "resultsPanel",
    widgetGroup: WidgetGroup ? WidgetGroup.DISPLAY : 5,
    name: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "displayBathroomWidget"),
    description: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "displayBathroomWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        styleIds: [],
        bathroomFontSize: "default-font-size",
        bathroomFontColor: "font-default",
        nameStyle: "default",
        borderWidth: "",
        bathroomTextBackground: "background-default",
        borderLineColor: "border-default",
        iconConfiguration: defaultIconConfigurationValues,
    }),
    container: false,
    async render(widget, context) {
        return <Bathroom options={widget.options} context={context} />;
    },
};
